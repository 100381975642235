.AccordionItem {
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
}
.Header {
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid var(--lawmonitor-gray-300);
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Header.First {
    border-top: none;
}

.Title {
    display: flex;
    align-items: center;
}

.Title svg {
    margin-left: 8px;
}

.Icon {
    transform: rotate(0);
}
.Open .Icon {
    transform: rotate(90deg);
}
.Icon path {
    fill: var(--lawmonitor-gray-400);
}

.Content {
    margin-top: 4px;
    margin-bottom: 18px;
}

.List {
    margin: 0;
    padding: 0;
    list-style: none;
}
.Disabled {
    opacity: 0.6;
}
